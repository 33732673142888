import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import { RouterLink } from '@angular/router';


@Component({
  selector: "dashboard-sidebarmenu",
  templateUrl: "dashboard-sidebarmenu.component.html",
  styleUrl: "dashboard-sidebarmenu.component.less",
  standalone: true,
  imports:[CommonModule,IonicModule , RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardSidebarmenuComponent {
   showMenuDiv = input<boolean >(false)

   showMenuDivGui = signal<boolean>(this.showMenuDiv());

   public updateShowMenuDiv(){
    this.showMenuDivGui.update(a=>!a);
   }
}
