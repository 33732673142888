import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    console.log(`Request for ${req.urlWithParams} started at ${started}`);

    return next.handle(req).pipe(
      tap(
        // Logs the successful response
        event => {
          const elapsed = Date.now() - started;
          console.log(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
        },
        // Logs the error response
        error => {
          const elapsed = Date.now() - started;
          console.error(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`, error);
        }
      )
    );
  }
}
