import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  input,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { OrderModel } from '../../../../../../../../libs/shared/models/src/models/order.model';

@Component({
  selector: 'app-order-lists',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatCardModule,
    MatButton,
    MatPaginatorModule,
    MatSort,
  ],
  templateUrl: './order-lists.component.html',
  styleUrl: './order-lists.component.less',
})
export class OrderListsComponent implements AfterViewInit {
  constructor(private router: Router) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public orderlists = input<OrderModel[]>([]);
  public displayedColumns = input<string[]>([
    'id',
    'username',
    'restaurant',
    'amount',
    'payment',
    'action',
  ]);
  public orderPaginator = input<boolean>(false);
  public showSearch = input<boolean>(false);

  ngAfterViewInit() {
    this.setSortingAndPagination(this.dataSource());
  }

  public dataSource = computed(() => {
    const newDataSource = new MatTableDataSource<OrderModel>(
      this.orderPaginator() ? this.orderlists() : this.orderlists().slice(0, 7),
    );
    this.setSortingAndPagination(newDataSource);
    return newDataSource;
  });

  public setSortingAndPagination(
    dataSource: MatTableDataSource<OrderModel>,
    search?: string,
  ): void {
    dataSource.data = search
      ? this.orderlists().filter(
          (order) =>
            order.id?.toLocaleLowerCase().includes(search) ||
            order.userName?.toLocaleLowerCase().includes(search),
        )
      : this.orderlists();
    dataSource.sort = this.sort;
    dataSource.paginator = this.paginator;
  }
  getRecord(id: string) {
    this.router.navigate([`/home/order/${id}`]);
  }

  applySearch(event: any) {
    this.setSortingAndPagination(
      this.dataSource(),
      (event?.target as HTMLInputElement).value.trim().toLowerCase(),
    );
  }
}
