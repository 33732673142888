import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DriverModel } from 'libs/shared/models/src/models/driver.model';
import { switchMap, tap } from 'rxjs';
import {
  DriverApiService,
  makeId,
} from '../../../../api-services/driver.api.service';
import { CoverPageComponent } from '../../stateless-components/cover-page/cover-page.component';

@Component({
  selector: 'drivers-info-from',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CoverPageComponent],
  templateUrl: './drivers-info-form.component.html',
  styleUrl: './drivers-info-form.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriversInfoFormComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private driverApiService: DriverApiService,
    private destroyRef: DestroyRef,
    private fb: FormBuilder,
  ) {}
  driverForm = signal<FormGroup>(
    this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      gender: [null, Validators.required],
      address: [null, Validators.required],
      latitude: [null, Validators.required],
      longitude: [null, Validators.required],
      city: [null, Validators.required],
    }),
  );
  driver = signal<DriverModel | null>(null);

  isEditAble = signal<boolean>(false);

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          const requestId = params.get('id');
          return this.driverApiService.fetchDriverById(requestId);
        }),
        tap((driverinfo) => {
          this.driver.update(() => driverinfo);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    if (this.driver()) {
      this.driverForm.update(() =>
        this.fb.group({
          firstName: [this.driver()?.firstName],
          lastName: [this.driver()?.lastName],
          gender: [this.driver()?.gender],
          address: [this.driver()?.address],
          latitude: [this.driver()?.location?.latitude],
          longitude: [this.driver()?.location?.longitude],
          city: [this.driver()?.city],
        }),
      );
      this.driverForm.update((el) => {
        el.disable();
        return el;
      });
      this.isEditAble.update(() => true);
    } else {
      this.driver.update((el) => ({ id: makeId() }) as DriverModel);
    }
  }

  back() {
    this.router.navigate([`/home/drivers-panel`]);
  }
  edit() {
    this.driverForm.update((el) => {
      el.enable();
      return el;
    });
    this.isEditAble.update(() => false);
  }

  submitDriver() {
    this.driver.update((el) => {
      el!.address = this.driverForm().value.address;
      el!.lastName = this.driverForm().value.lastName;
      el!.firstName = this.driverForm().value.firstName;
      el!.gender = this.driverForm().value.gender;
      el!.location = {
        latitude: this.driverForm().value.latitude,
        longitude: this.driverForm().value.longitude,
      };
      return el;
    });

    this.driverUpdate(this.driver()!);
    this.driverForm.update((el) => {
      el.disable();
      return el;
    });
    this.isEditAble.update(() => true);
  }

  driverUpdate(driverData: DriverModel) {
    this.driverApiService
      .updateDriver(driverData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (el: DriverModel) => {
          this.driver.update(() => el);
        },
      });
  }
}
