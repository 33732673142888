import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentType } from 'lib-shared-utils/lib/shared-utils/type';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHelperService } from '../helper/http.helper.service';

const jwtToken ="jwtToken";
const refreshToken = "refreshToken"
export interface AuthResponse {
  jwtToken: string;
  refereshToken: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  isAccountVerified:boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private env: EnvironmentType;


  constructor(private router: Router,
    private httpService:HttpHelperService,
    @Inject("APP_CONFIG") config: {
    env: EnvironmentType;
  }
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(this.getUserFromStorage());
    this.currentUser = this.currentUserSubject.asObservable();
    this.env = config.env;
  }

  // Get current user as an observable
  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  // User login method
  login(email: string, password: string): Observable<AuthResponse> {
    const body = JSON.stringify({ email, password });
     return this.httpService.post<AuthResponse>(this.env.httpAuthUrl, body)
      .pipe(
        map((response) => {
          if (response && response.jwtToken) {
            this.setSession(response); // Store user details and JWT in local storage
            this.currentUserSubject.next(response.user); // Set current user
          }
          return response;
        }),
        catchError(this.handleError)
      );
  }

    // User login method
    submitOtp(email: string, emailOtp: string): Observable<AuthResponse> {
      const body = JSON.stringify({ email, emailOtp });
       return this.httpService.post<AuthResponse>(this.env.httpValidateEmailOtpUrl, body)       
        .pipe(
          map((response) => {
            if (response && response.jwtToken) {
              this.setSession(response); // Store user details and JWT in local storage
              this.currentUserSubject.next(response.user); // Set current user
            }
            return response;
          }),
          catchError(this.handleError)
        );
    }

  register(email: string, password: string): Observable<AuthResponse> {
    const body = JSON.stringify({ email, password });

    return this.httpService.post<AuthResponse>(this.env.httpSignUpEmailUrl, body)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  logout() {
    localStorage.removeItem(jwtToken);
    localStorage.removeItem(refreshToken);
    localStorage.removeItem('user');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    return !!this.getJwtToken();
  } 

  private setSession(authResponse: AuthResponse): void {
    localStorage.setItem(jwtToken, authResponse.jwtToken);
    localStorage.setItem(refreshToken, authResponse.refereshToken);    
   // localStorage.setItem('user', JSON.stringify(authResponse.user));
  }

  private getUserFromStorage(): any {
    const user = localStorage.getItem('user');
    //return user ? JSON.parse(user) : null;
  }

  private handleError(error: any) {
    let errorMessage = 'An error occurred';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else if (error.error && error.error.message) {
      errorMessage = error.error.message;
    }
    return throwError(() => new Error(errorMessage));
  }

  setJwtToken(token: string) {
    localStorage.setItem(jwtToken, token);
  }
  
  getJwtToken(): string | null {
    return localStorage.getItem(jwtToken);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(refreshToken);
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    return this.httpService.post<AuthResponse>(this.env.httpAuthRefreshTokenUrl, { refreshToken })
  }

}
