import { Inject, Injectable } from "@angular/core";

import { UserModel } from "@frontend/shared/models";
import { HttpHelperService } from "@frontend/shared/services/";
import { map, Observable, take } from 'rxjs';
import { DashboardEnvironmentType } from "../../environments/env.type";


@Injectable({
  providedIn: 'root',
})
export class UserApiService {

  private env: DashboardEnvironmentType;
  constructor(private httpService:HttpHelperService,
        @Inject("APP_CONFIG") config: {
    env: DashboardEnvironmentType;
  }
  ){
    this.env = config.env;
  }

  public getUserList(){
    return this.httpService.getList<UserModel>(this.env.httpUserListUrl);
}

  public fetchUserRequestWithUserById(
    id: string | null,
  ): Observable<UserModel> {
    return this.httpService
      .getList<UserModel>(this.env.httpUserListUrl)
      .pipe(
        map((el) =>
          id ? el.filter((res) => res.id == id)[0] : ({ id } as UserModel),
        ),
        take(1),
      );
  }
}
