import { CommonModule } from "@angular/common";
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  input,
  OnInit,
  signal,
} from "@angular/core";
import { Chart, registerables } from "chart.js";
import { format } from "date-fns";



export interface BarChart {
  title: string;
  period: "TODAY" | "WEEK" | "MONTH";
  start: Date;
  end: Date;
  dataValues: number[];
}

@Component({
  selector: "bar-chart",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "bar-chart.component.html",
  styleUrl: "bar-chart.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent implements AfterViewInit{
  public chart = signal<Chart | undefined>(undefined);
  barchart = input.required<BarChart>();
  id = input.required<string>();
  constructor(){
    Chart.register(...registerables);
 }
 ngAfterViewInit(): void {
    if (this.id() && this.barchart()){
      this.createChart();
    }
  }


  createChart() {
    const barChartOptions = {
      responsive: true,  // This ensures the chart resizes to fit the container
      maintainAspectRatio: false,  // Allows the chart to adapt to the container's dimensions
 
    };
    let data: {
      labels: string[],
      datasets: [
        {
          label:string,
          data: number[],
          backgroundColor: string,
        },
      ],
    };
    if (this.barchart().period == "TODAY") {
      data = {
        // values on X-Axis
        labels: ["Today"],
        datasets: [
          {
            label: this.barchart().title,
            data: this.barchart().dataValues,
            backgroundColor: "#ff6378",
          },
        ],
      };
    } else if (this.barchart()?.period == "WEEK") {
      data = {
        // values on X-Axis
        labels: this.weeekMonthLabels(this.barchart().start, this.barchart().end),
        datasets: [
          {
            label: this.barchart().title,
            data: this.barchart().dataValues,
            backgroundColor: "#ff6378",
          },
        ],
      };
    } else {
      data = {
        // values on X-Axis
        labels: this.weeekMonthLabels(this.barchart().start, this.barchart().end),
        datasets: [
          {
            label: this.barchart()?.title,
            data: this.barchart().dataValues,
            backgroundColor: "#ff6378",
          },
        ],
      };
    }
    if (this.id() && this.chart){

      this.chart.update(
      () =>{
       return new Chart( this.id(), {
          type: "bar", //this denotes tha type of chart
          data:data,
          options: barChartOptions          
        })
      }
    );
  }
  }


  weeekMonthLabels(startDate: Date, endDate: Date) {
    const getDaysArray = (start: Date, end: Date) => {
      const arr = [];
      for (
        const dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(format(dt, 'yyyy-MM-dd'));
      }
      return arr;
    }; 
    return getDaysArray(startDate, endDate);
  }
  
}
