import { CommonModule } from '@angular/common';
import {ChangeDetectionStrategy, Component, input, signal} from '@angular/core';

@Component({
    selector: 'user-profile',
    standalone: true,
    imports: [
        CommonModule,
    ],
    templateUrl: './user-profile.component.html',
    styleUrl: './user-profile.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
    fullName = input<string>('');
    email = input<string>('');
    mobileNo = input<string>('');
    countDetail = input<{
        order:number
        address:number
        reviews:number
    }>( {
        order:Math.floor(Math.random() * 90 + 10),
        address:Math.floor(Math.random() * 90 + 10),
        reviews:Math.floor(Math.random() * 90 + 10)
    });
 }
