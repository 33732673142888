@switch (this.barchart().period) {
  @case ("TODAY") {
      <div class="chart-container" >
            <canvas  id='TODAY' >{{ this.chart() }}</canvas>
      </div>
  }
  @case ("WEEK") {
      <div class="chart-container" >
            <canvas  id='WEEK' >{{ this.chart() }}</canvas>
      </div>  }
  @case ("MONTH") {
      <div class="chart-container">
            <canvas  id='MONTH' >{{ this.chart() }}</canvas>
      </div>  
      }
}

