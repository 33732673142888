import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SummaryData } from '../../../partners-components/control-panel/control-panel.component';
import { UiColorBoxComponent } from '../../stateless-components/ui-mini-box/ui-mini-box.component';

@Component({
  selector: 'all-summary',
  standalone: true,
  imports: [CommonModule, UiColorBoxComponent],
  templateUrl: 'all-summary.component.html',
  styleUrl: 'all-summary.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllSummaryComponent {
  summaryArr = input<SummaryData[]>();
}
