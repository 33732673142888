<div class="header">
  <h1>Manage Drivers</h1>
  <button (click)="back()">&#10005;</button>
</div>
<cover-page></cover-page>

<div class="driver__form">
  <div class="title">
    <h3>DRIVER DETAILS :</h3>
  </div>

  <form [formGroup]="driverForm()">
    <div class="form-group">
      <label for="firstName">First Name</label>
      <input
        id="firstName"
        formControlName="firstName"
        type="text"
        name="firstName"
      />
    </div>
    <div class="form-group">
      <label for="lastName">Last Name</label>
      <input
        id="lastName"
        formControlName="lastName"
        type="text"
        name="lastName"
      />
    </div>
    <div class="form-group">
      <label for="gender">Gender</label>
      <input id="gender" formControlName="gender" type="text" name="gender" />
    </div>

    <div class="form-group full-width">
      <label for="address">Driver Address</label>
      <textarea
        id="address"
        formControlName="address"
        type="text"
        name="address"
        rows="5"
      ></textarea>
    </div>
    <div class="center full-width">
      <p>
        Select latitude and longitude from here
        <a href="https://www.mapcoordinates.net/en" target="_blank">
          map coordinates
        </a>
      </p>
      <p>Please enter valid latitude and longitude</p>
    </div>
    <div class="form-group">
      <label for="latitude">Latitude</label>
      <input
        id="latitude"
        formControlName="latitude"
        type="number"
        name="latitude"
      />
    </div>
    <div class="form-group">
      <label for="longitude">Longitude</label>
      <input
        id="longitude"
        formControlName="longitude"
        type="number"
        name="longitude"
      />
    </div>
    <div class="form-group">
      <label for="city">Select City</label>
      <input id="city" formControlName="city" type="text" name="city" />
    </div>
  </form>
</div>

<div class="footer">
  <button (click)="back()">close</button>
  @if (isEditAble()) {
    <button class="yellow" (click)="edit()">Edit</button>
  } @else {
    <button class="blue" (click)="submitDriver()">Submit</button>
  }
</div>
