import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingSpinnerComponent } from 'libs/shared/ui/src';

@Component({
  standalone: true, 
  imports: [ RouterModule, LoadingSpinnerComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
})
export class AppComponent {
  title = 'dashboard';
}
