import { CommonModule, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  signal,
  ViewChild,
  type ElementRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserModel } from '@frontend/shared/models';
import type { OrderModel } from 'libs/shared/models/src/models/order.model';
import { switchMap, tap } from 'rxjs';
import { OrderApiService } from '../../../../api-services/order.api.service';
import { UserApiService } from '../../../../api-services/user.api.service';

@Component({
  selector: 'order-info',
  standalone: true,
  imports: [CommonModule, NgTemplateOutlet],
  templateUrl: './order-info.component.html',
  styleUrl: './order-info.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderInfoComponent {
  user = signal<UserModel | null>(null);
  order = signal<OrderModel | null>(null);
  constructor(
    private route: ActivatedRoute,
    private userApiService: UserApiService,
    private orderApiService: OrderApiService,
    private destroyRef: DestroyRef,
  ) {}
  @ViewChild('statusInput') statusInput!: ElementRef;

  public orderTrack = signal<{ title: String; date: Date }[]>([
    { title: 'Order created', date: new Date(2024, 9, 22, 9, 22) },
    {
      title: 'Order accepted by Walmart',
      date: new Date(2024, 9, 23, 10, 12),
    },
    {
      title: 'Order refunded 0 by administrator',
      date: new Date(2024, 9, 24, 11, 24),
    },
  ]);

  public orderDetaillist = computed(() => this.order()?.shoppingCartModel);

  public orderDetailOtherInfo = computed(() => [
    {
      title: 'Order items Total',
      amount: this.order()?.total,
    },
    {
      title: 'Order Delievery charges',
      amount: this.order()?.deliveryCharge,
    },
    {
      title: 'Split order Tax',
      amount: this.order()?.tax,
    },
    {
      title: 'Split order Discount',
      amount: this.order()?.discount,
    },
    {
      title: 'Split order Wallet Discount',
      amount: this.order()?.walletDiscount,
    },
  ]);

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          const requestId = params.get('id');
          return this.orderApiService.fetchOrderById(requestId);
        }),
        switchMap((orderinfo) => {
          this.order.update(() => orderinfo);
          return this.userApiService.fetchUserRequestWithUserById(
            orderinfo.userId,
          );
        }),
        tap((userinfo) => {
          this.user.update(() => {
            userinfo.mobileNo = '0123468154752';
            return userinfo;
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateTrack = (value: string) =>
    this.orderTrack.update((el) => {
      el.push({ title: value, date: new Date() });
      this.statusInput.nativeElement.value = '';
      return el;
    });
}
