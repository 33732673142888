import { EnvironmentType, RouteMetadata } from '@frontend/shared/utils/type.ts';

export type DashboardEnvironmentType = EnvironmentType & DashboardAppConfig

    export type DashboardAppConfig = {
      httpUserListUrl: RouteMetadata;
  };





 export const defaultEnvironemntConfig: DashboardEnvironmentType = {
     production: false,
     baseUrl: 'http://localhost:3000/',
     apiVersion: "v1", // Optional: if you want to include versioning in your API URL
     httpAuthUrl: {
         route: "/auth/login",
         isPublic: true
     },
     httpSignUpEmailUrl: {
         route: "/auth/signup/emailotp",
         isPublic: true
     },
     httpSignUpMobileUrl: {
         route: "/auth/signup/mobileotp",
         isPublic: true
     },
     httpValidateEmailOtpUrl: {
        route: "/auth/validate/emailotp",
        isPublic: true
     },
     httpValidateMobileOtpUrl: {
        route: "/auth/validate/mobileotp",
        isPublic: true
     },
     httpUserListUrl :{
        route: "/user/lists",
        isPublic: false
     },
     httpUserUrl :{
        route: "/user/",
        isPublic: false
     },
     httpAuthRefreshTokenUrl :{
        route: "/auth/refresh-token",
        isPublic: true
     }
 }