import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  input,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserModel } from '@frontend/shared/models';

@Component({
  selector: 'app-user-lists',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatCardModule,
    MatButton,
    MatPaginatorModule,
    MatSort,
  ],
  templateUrl: './user-lists.component.html',
  styleUrl: './user-lists.component.less',
})
export class UserListsComponent implements AfterViewInit {
  //TODO temperory fix, remove contructor and get Record method
  constructor(private router: Router) {}

  public userlists = input<UserModel[]>([]);
  public displayedColumns = input<string[]>([]);
  public usePaginator = input<boolean>(false);
  public showSearch = input<boolean>(false);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.setSortingAndPagination(this.dataSource());
  }
  public dataSource = computed(() => {
    const newDataSource = new MatTableDataSource<UserModel>(
      this.usePaginator() ? this.userlists() : this.userlists().slice(0, 7),
    );
    this.setSortingAndPagination(newDataSource);
    return newDataSource;
  });

  public setSortingAndPagination(
    dataSource: MatTableDataSource<UserModel>,
    search?: string,
  ): void {
    if (search) {
      dataSource.data = this.userlists().filter(
        (user) =>
          user.firstName?.toLocaleLowerCase().includes(search) ||
          user.lastName?.toLocaleLowerCase().includes(search),
      );
    }

    dataSource.sort = this.sort;
    dataSource.paginator = this.paginator;
  }

  getRecord(id: string) {
    this.router.navigate([`/home/user/${id}`]);
  }

  applySearch(event: any) {
    this.setSortingAndPagination(
      this.dataSource(),
      (event?.target as HTMLInputElement).value.trim().toLowerCase(),
    );
  }
}
