import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  computed,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, tap } from 'rxjs';
import { UserApiService } from '../../../../api-services/user.api.service';
import { UserProfileComponent } from '../../stateless-components/user-profile/user-profile.component';
import { UserSubDetailsComponent } from '../../stateless-components/user-sub-details/user-sub-details.component';

@Component({
  selector: 'user-info',
  standalone: true,
  imports: [CommonModule, UserProfileComponent, UserSubDetailsComponent],
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent {
  constructor(
    private route: ActivatedRoute,
    private userApiService: UserApiService,
    private destroyRef: DestroyRef,
  ) {}

  userFullName = signal<string>('');
  userEmail = signal<string>('');
  userMobileNo = signal<string>('');

  getUserInfo = computed(() =>
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          const requestId = params.get('id');
          return this.userApiService.fetchUserRequestWithUserById(requestId);
        }),
        tap((requestUser) => {
          this.userFullName.update(() =>
            requestUser.firstName && requestUser.lastName
              ? requestUser.firstName + '  ' + requestUser.lastName
              : '',
          );
          this.userEmail.update(() => requestUser.email ?? '');
          this.userMobileNo.update(() => requestUser.mobileNo ?? '');
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(),
  );

  ngOnInit() {
    this.getUserInfo();
  }
}
