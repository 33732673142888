import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {BarChartComponent, BarChart} from '../../stateless-components/bar-chart/bar-chart.component';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from "@angular/material/card";

export interface ChartInfo{
    title : string,
    subTitle: string,
    barchart:BarChart,
    id: string
}

@Component({
    selector: 'show-bars',
    standalone: true,
    imports: [
        CommonModule,BarChartComponent, MatCard,MatCardHeader,MatCardSubtitle, MatCardTitle, MatCardContent
    ],
    templateUrl: "show-bars.component.html",
    styleUrl: 'show-bars.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowBarsComponent { 
    chartInfo = input<ChartInfo[]>()
}
