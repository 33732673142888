import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthApiService } from 'lib-shared-services/services/auth/auth.api.service';
 
@Component({
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule],
  template: '',
})
export class LogoutComponent implements OnInit{

  constructor(
      private authService:AuthApiService
  ) { }

  ngOnInit() {
      this.authService.logout();
  }

  
}
