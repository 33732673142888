import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import {OrderListsComponent} from '../order-lists/order-lists.component';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatButton} from '@angular/material/button';
@Component({
    selector: 'user-sub-details',
    standalone: true,
    imports: [
        CommonModule,MatTabsModule,MatTableModule, MatCardModule, MatButton
    ],
    templateUrl: "./user-sub-details.component.html",
    styleUrl: './user-sub-details.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSubDetailsComponent {
  OrdersDisplayedColumns: string[] = ['restaurantname', 'amount', 'payment'];
  dataSource = ELEMENT_DATA;
  addressDisplayedColumns =     ["id", "title" ,"address", "house" ,"landmark" ,"pincode" ,"actions"]
  ReviewsDisplayedColumns =  ["id","from","on","rating" ,"comment"]
 }

 export interface UserOrdersElement {
    restaurant: string;
    amount: number;
    payment: string;
  }
  
  const ELEMENT_DATA: UserOrdersElement[] = [
    { restaurant: 'Hydrogen Cafe', amount: 750, payment: 'CARD'},
    { restaurant: 'Malaka Spice', amount: 650, payment: 'CARD'},
    { restaurant: 'The Sassy Spoon', amount: 250, payment: 'CASH'},
    { restaurant: 'Blue Nile Restaurant', amount: 850, payment: 'CARD'},
    { restaurant: 'Persian Darbar', amount: 1000, payment: 'CASH'},
    { restaurant: 'Baan Tao', amount: 1250, payment: 'CARD'},
  ];