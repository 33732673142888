import {ChangeDetectionStrategy, Component, signal} from '@angular/core';
import {DashboardSidebarmenuComponent} from '../dashboard-sidebarmenu/dashboard-sidebarmenu.component';
import {CommonModule} from '@angular/common';
import { DashboardHeaderComponent } from '../dashboard-header/dashboard-header.component';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'dashboard-home',
    templateUrl: "dashboard-home.component.html",
    styleUrl: 'dashboard-home.component.less',
    standalone:true,
    imports:[CommonModule,DashboardSidebarmenuComponent ,DashboardHeaderComponent ,RouterOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboradHomeComponent {

    openMenu =  signal<boolean>(false)

    MenuSignal(value: boolean) {
        this.openMenu.update(()=>value)
    }

 }
