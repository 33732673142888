import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthApiService } from 'lib-shared-services/services/auth/auth.api.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthApiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getJwtToken();

    if (accessToken) {
      request = this.addToken(request, accessToken);
    }

    return next.handle(request).pipe(
      catchError(error => {
        console.log("error : ",error)
        //error instanceof HttpErrorResponse && 
        if (error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    const excludedUrls = [
        environment.httpAuthUrl.route,
        environment.httpSignUpEmailUrl.route,
        environment.httpSignUpMobileUrl.route,
        environment.httpValidateEmailOtpUrl.route,
        environment.httpValidateMobileOtpUrl.route,
        environment.httpAuthRefreshTokenUrl.route,

    ];

    const shouldExclude = excludedUrls.some(url => request.url.includes(url));

    if (!shouldExclude && token){
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return request;

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.authService.setJwtToken(token.jwtToken);
          this.refreshTokenSubject.next(token.refereshToken);
          return next.handle(this.addToken(request, token.jwtToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.authService.logout();
          return throwError(err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwtToken => next.handle(this.addToken(request, jwtToken)))
      );
    }
  }
}
