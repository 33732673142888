


@if( this.chartInfo()?.length ){@for ( i of this.chartInfo(); track i.id) {
 


<div class="conten">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ i.title }}</mat-card-title>
      <mat-card-subtitle>{{ i.title }}</mat-card-subtitle>
     </mat-card-header>
      <mat-card-content>
        <bar-chart [barchart]="i.barchart" [id]="i.id"></bar-chart>

      </mat-card-content>
      </mat-card>

</div>

  }}
  
  