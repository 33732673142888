import 'reflect-metadata';
import { Serializable } from './type';

 
export class JsonSerializer {
    static serializable(): PropertyDecorator {
        return function (target: Object, propertyKey: string | symbol): void {
            const existingSerializableProperties: string[] = Reflect.getMetadata('serializableProperties', target) || [];
            Reflect.defineMetadata('serializableProperties', [...existingSerializableProperties, propertyKey], target);
        };
    }

    static serialize(obj: any): any {
        const serializedObj: any = {};
        const properties: string[] = Reflect.getMetadata('serializableProperties', obj) || [];

        for (const key of properties) {
            serializedObj[key] = obj[key];
        }

        return JSON.stringify(serializedObj);
    }

    static deserialize<T extends object>(json: any, cls: { new (): T }): T {
        const instance = new cls();
        const properties: (keyof T)[] = Reflect.getMetadata('serializableProperties', instance) || [];
    
        for (const key of properties) {
            if (json[key as string] !== undefined) {
                instance[key] = json[key as keyof typeof json];
            }
        }
    
        return instance;
    }
    
    
}
