
<div class="wrapper">
        <dashboard-sidebarmenu [showMenuDiv]="this.openMenu()"></dashboard-sidebarmenu>
    <div class="container">
        <dashboard-header (open)="this.MenuSignal($event)"></dashboard-header>
        <div class="body">
            <router-outlet></router-outlet>

        </div>
    </div>
</div>