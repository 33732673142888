import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RouteMetadata } from '@frontend/shared/utils/';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class HttpHelperService {
  
   private host: string;
   constructor(private http: HttpClient,
    @Inject("HOST_URL") host: string
  ) {
    this.host = host;
  }


  private prepareUrl(routeMeta: RouteMetadata){
      const trimmedHost = this.host.replaceAll("/+$", "");
      const trimmedUrl = routeMeta.route.replaceAll("^/+","");
      return trimmedHost.concat(trimmedUrl);
  }

  getList<T>(routeMeta: RouteMetadata, headers?: HttpHeaders): Observable<T[]> {
    return this.http.get<T[]>(this.prepareUrl(routeMeta), { headers: this.createHeaders(headers) }).pipe(
      map((response: T[]) => response),
      //catchError(this.handleError)
    );
  }

  // GET Request
  get<T>(routeMeta: RouteMetadata, headers?: HttpHeaders): Observable<T> {
    return this.http.get<T>(this.prepareUrl(routeMeta), { headers: this.createHeaders(headers) }).pipe(
      map((response: T) => response),
      //catchError(this.handleError)
    );
  }

  // POST Request
  post<T>(routeMeta: RouteMetadata, data: any, headers?: HttpHeaders): Observable<T> {
    return this.http.post<T>(this.prepareUrl(routeMeta), data, { headers: this.createHeaders(headers) }).pipe(
      map((response: T) => response),
      //catchError(this.handleError)
    );
  }

  // PUT Request
  put<T>(routeMeta: RouteMetadata, data: any, headers?: HttpHeaders): Observable<T> {
    return this.http.put<T>(this.prepareUrl(routeMeta), data, { headers: this.createHeaders(headers) }).pipe(
      map((response: T) => response),
      catchError(this.handleError)
    );
  }

  // DELETE Request
  delete<T>(routeMeta: RouteMetadata,  headers?: HttpHeaders): Observable<T> {
    return this.http.delete<T>(this.prepareUrl(routeMeta), { headers: this.createHeaders(headers) }).pipe(
      map((response: T) => response),
      catchError(this.handleError)
    );
  }

  // Method to handle errors
  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Backend error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => error);
  }

  // Method to create HTTP headers, with default options (e.g., authorization)
  private createHeaders(headers?: HttpHeaders): HttpHeaders {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');
    
    return headers;
  }
}
