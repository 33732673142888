import {
  ChangeDetectionStrategy,
  Component,
  Output,
  signal,
  EventEmitter,
} from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";


@Component({
  selector: "dashboard-header",
  templateUrl: "dashboard-header.component.html",
  styleUrl: "dashboard-header.component.less",
  standalone: true,
  imports: [CommonModule, IonicModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardHeaderComponent {
  showMenuDiv = signal<boolean>(true);
  @Output() public open: EventEmitter<boolean> = new EventEmitter();
  check$ = toObservable(this.showMenuDiv).subscribe((el) => this.open.emit(el));
  openMenu() {
    this.showMenuDiv.update(() => !this.showMenuDiv());
  }
}
