import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { DriverModel } from '../../../../../../../libs/shared/models/src/models/driver.model';
import { DriverApiService } from '../../../api-services/driver.api.service';

@Component({
  selector: 'display-drivers',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatCardModule,
    MatButton,
    MatPaginatorModule,
    MatSort,
  ],
  templateUrl: './display-drivers.component.html',
  styleUrl: './display-drivers.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayDriversComponent implements OnInit {
  constructor(
    private driverApiService: DriverApiService,
    private router: Router,
    private destroyRef: DestroyRef,
  ) {}

  public driverlists = signal<DriverModel[]>([]);
  public displayedColumns = signal<string[]>([
    'id',
    'cover',
    'name',
    'mobileNo',
    'email',
    'action',
  ]);

  ngOnInit(): void {
    this.driverApiService.getDriverList().subscribe({
      next: (driver: DriverModel[]) => {
        this.driverlists.set([...driver]);
      },
    });
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.setSortingAndPagination(this.dataSource());
  }

  public dataSource = computed(() => {
    const newDataSource = new MatTableDataSource<DriverModel>(
      this.driverlists(),
    );
    this.setSortingAndPagination(newDataSource);
    return newDataSource;
  });

  public setSortingAndPagination(
    dataSource: MatTableDataSource<DriverModel>,
    search?: string,
  ): void {
    dataSource.data = search
      ? this.driverlists().filter((driver) =>
          ['id', 'firstName', 'lastName', 'mobileNo', 'email'].some((el) =>
            (driver as any)[el]?.toLocaleLowerCase().includes(search),
          ),
        )
      : this.driverlists();
    dataSource.sort = this.sort;
    dataSource.paginator = this.paginator;
  }

  getRecord(id: string) {
    this.router.navigate([`/home/driver/${id}`]);
  }

  addRecord() {
    this.router.navigate([`/home/driver`]);
  }

  applySearch(event: any) {
    this.setSortingAndPagination(
      this.dataSource(),
      (event?.target as HTMLInputElement).value.trim().toLowerCase(),
    );
  }

  changeStatus(id: string) {
    const status =
      this.driverlists().find((el) => el.id === id)?.status === 'ACTIVE'
        ? 'DEACTIVE'
        : 'ACTIVE';
    this.driverApiService
      .updateDriverStatus(id, status)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (driver: DriverModel[]) => {
          this.driverlists.update(() => [...driver]);
        },
      });
  }

  driverDelete(id: string) {
    this.driverApiService
      .deleteDriver(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (driver: DriverModel[]) => {
          this.driverlists.update(() => [...driver]);
        },
      });
  }
}
