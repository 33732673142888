

<div class="profile__img">
<img src="assets/img/img_avatar.png" alt="" class="avatar"> 
</div>

<div class="user-detail">
    <div class="user-detail-title">BASIC INFORMATIONS</div>
    <div class="user-detail-info"  >
        <div class="user-detail-info--title">NAME:</div>
        <div class="user-detail-info--detail">{{this.fullName()}}</div>
    </div>
    <div class="user-detail-info"  >
        <div class="user-detail-info--title">EMAIL:</div>
        <div class="user-detail-info--detail">{{this.email()}}</div>
    </div>
    <div class="user-detail-info"  >
        <div class="user-detail-info--title">CONTACT NO:</div>
        <div class="user-detail-info--detail">{{this.mobileNo()}}</div>
    </div>
</div>

<div class="user-count__detail">
<div class="user-count__detail-box">
    <div class="user-count__detail-box--title">Orders</div>
    <div class="user-count__detail-box--number">{{countDetail().order}}</div>
</div>
<hr>
<div class="user-count__detail-box">
    <div class="user-count__detail-box--title">address</div>
    <div class="user-count__detail-box--number">{{countDetail().address}}</div>
</div>
<hr>
<div class="user-count__detail-box">   
     <div class="user-count__detail-box--title">Reviews</div>
    <div class="user-count__detail-box--number">{{countDetail().reviews}}</div>
</div>
</div>