import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'ui-mini-box',
    standalone: true,
    imports: [
        CommonModule,
    ],
    templateUrl: "ui-mini-box.component.html",
    styleUrl: 'ui-mini-box.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiColorBoxComponent { 
    title =  input<string>()
    totalValue = input<string>()
}
